<template>
  <ModalStep
    id="matching-content"
    :header="{
      title:
        roleRef === RoleEnumType.MENTOR
          ? t('matching.fillingCriterias.mentor.title')
          : t('matching.fillingCriterias.mentee.title'),
    }"
  >
    <template #headerBeforeElement>
      <ProgramsDropdown />
    </template>

    <template #bodyInformation>
      <strong>
        <span v-if="roleRef === RoleEnumType.MENTOR">
          {{ t('matching.fillingCriterias.mentor.body') }}
        </span>
        <span v-else>
          {{ t('matching.fillingCriterias.mentee.body') }}
        </span>
      </strong>
    </template>

    <template #bodyContent>
      <MatchingInformationContainer />
    </template>

    <template #footer>
      <div class="c-btns-container">
        <CustomButton usage="button" type="button" color="white" text="Retour" @emit:click="goPrevStep"></CustomButton>
        <CustomButton
          :isLoading="isSubmittingRef"
          :text="`${roleRef === RoleEnumType.MENTOR ? t('matching.fillingCriterias.button.fillCriterias') : t('matching.fillingCriterias.button.lookForMentor')}`"
          usage="button"
          type="submit"
          color="primary"
          @emit:click="onSubmit"
        ></CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { computed } from 'vue'

import useAccount from '@/v1.5/features/auth/hooks/use-account.hook'
import { RoleEnumType } from '@/v1.5/features/auth/types'
import { menteeMatchSuggestMentorMutation, mentorMatchFillCriteriaMutation } from '@/v1.5/features/matching/api'
import MatchingInformationContainer from '@/v1.5/features/matching/components/matching-modals/matching-modal-content/matching-information-container/matching-information-container.vue'
import useMatchingInformation from '@/v1.5/features/matching/hooks/use-matching-information.hook'
import ProgramsDropdown from '@/v1.5/features/programs/components/programs-dropdown/programs-dropdown.vue'
import { selectGetSelectedProgramId } from '@/v1.5/features/programs/stores/programs/programs.selectors'
import programsStore from '@/v1.5/features/programs/stores/programs/programs.store'
import CustomButton from '@/v1.5/features/ui/components/button/custom-button.vue'
import ModalStep from '@/v1.5/features/ui/components/modal/modal-step/modal-step.vue'
import type { MenteeMatchingModalType, MentorMatchingModalType } from '@/v1.5/features/ui/store/modal/modal.store'
import modalsStore from '@/v1.5/features/ui/store/modal/modal.store'
import useToast from '@/v1.5/hooks/use-toasts.hook'
import { useI18n } from '@/v1.5/lib/i18n'
import { invalidateUser, resetMainQueriesCache } from '@/v1.5/utils/lib/vue-query'

const { t } = useI18n()
const toast = useToast()

const { accountRef } = useAccount()
const roleRef = computed(() => accountRef.value?.role)

const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)

const { mutate: mentorFillCriteria } = useMutation({
  mutationFn: mentorMatchFillCriteriaMutation,
  onError: (error) => {
    // if an error occurs, ...
    console.error(error)
    toast?.error(t('error.common'))
  },
  // we close the modal, show success and invalidate the queries
  onSuccess: async () => {
    // we hard reset the cache and invalide the queries to refetch everything in background
    await invalidateUser(accountRef.value!.id!)
    await resetMainQueriesCache(selectedProgramIdRef.value!)
    toast?.success(t('matching.fillingCriterias.success'))
  },
  onSettled: () => {
    isSubmittingRef.value = false
  },
  onMutate: () => {
    isSubmittingRef.value = true
  },
})

const { mutate: menteeMatchSuggestMentor } = useMutation({
  mutationFn: menteeMatchSuggestMentorMutation,
  onError: (error) => {
    if (error.message === 'No match found') {
      toast?.error(t('matching.fillingCriterias.noMentorMatching'))
    } else {
      toast?.error(t('error.common'))
    }
    // if an error occurs, we go back to the previous step (mentee)
    modalsStore.setModalStep<MenteeMatchingModalType>('mentee-matching', 'fill-criteria')
  },
  // we set the matched mentor id in the modal attached data and go to next step
  onSuccess: (data) => {
    if (data.message === 'No mentor available') {
      toast?.error(t('matching.fillingCriterias.noMentorAvailable'))
      modalsStore.setModalStep<MenteeMatchingModalType>('mentee-matching', 'fill-criteria')
    } else {
      modalsStore.setModalStep<MenteeMatchingModalType>('mentee-matching', 'matching-confirmation')
      modalsStore.updateModalAttachedData<MenteeMatchingModalType>('mentee-matching', {
        mentors: data.mentors,
      })
    }
  },
  onSettled: () => {
    isSubmittingRef.value = false
  },
  onMutate: () => {
    isSubmittingRef.value = true

    //  we show the loading screen (the matching will be done in the background)
    modalsStore.setModalStep<MenteeMatchingModalType>('mentee-matching', 'matching-loading')
  },
})

const onSubmitCallback = (filledCriteria: string) => {
  if (accountRef.value?.role === RoleEnumType.MENTOR) {
    // we fill the mentor criterias
    mentorFillCriteria({
      userId: accountRef.value.id!,
      criteria: filledCriteria,
      programId: selectedProgramIdRef.value!,
    })
  } else {
    // we suggest a mentor to the mentee
    menteeMatchSuggestMentor({
      criteria: filledCriteria,
      programId: selectedProgramIdRef.value!,
    })
  }
}

const { onSubmit, isSubmittingRef } = useMatchingInformation({
  onSubmitCallback,
})

const goPrevStep = () => {
  if (roleRef.value === RoleEnumType.MENTOR) {
    modalsStore.setModalStep<MentorMatchingModalType>('mentor-matching', 'goal-reminder')
  } else if (roleRef.value === RoleEnumType.MENTEE) {
    modalsStore.setModalStep<MenteeMatchingModalType>('mentee-matching', 'goal-reminder')
  }
}
</script>

<style lang="scss">
@import './matching-modal-content.scss';
</style>
