<template>
  <div class="matching-information-container">
    <div
      v-if="matchingInformationRef"
      v-html="prepareMatchingInformationHTML(matchingInformationRef, userCriteriaRef)"
    ></div>
    <div v-else class="matching-content-loading">
      <LoadingIcon color="primary" />
    </div>
  </div>
</template>

<script setup lang="ts">
import useMatchingInformation from '@/v1.5/features/matching/hooks/use-matching-information.hook'
import LoadingIcon from '@/v1.5/features/ui/components/icons/loading-icon.vue'

const { matchingInformationRef, userCriteriaRef, prepareMatchingInformationHTML } = useMatchingInformation({
  onSubmitCallback: () => null,
})
</script>

<style lang="scss"></style>
